<template>
  <div class="flex flex-col">
    <div
      class="flex flex-col items-start self-center w-11/12 mt-8 bg-yellow p-4 px-6 rounded-xl justify-center"
    >
      <h2
        class="mb-4 text-2xl font-black pl-6 text-center text-white self-center"
      >
        REPORTES
      </h2>
      <div class="bg-white w-full py-10 px-5 rounded-xl">
        <div class="flex flex-col border border-black rounded-xl py-4 px-4">
          <h1 class="text-3xl font-bold">
            Propietarios y Colaboradores Registrados
          </h1>
          <br />
          <p>
            Seleccione la fecha máxima y descargue el Reporte de Colaboradores y
            Propietarios Registrados
          </p>
          <div class="mt-1 flex">
            <div class="w-44 border-2 h-8 rounded-md border-black text-center">
              <input
                class="outline-none"
                type="datetime-local"
                id="fechaUsuario"
              />
            </div>
            <div
              @click="users()"
              class="h-10 cursor-pointer ml-40 bg-yellow w-28 border-black border flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              Descargar
            </div>
          </div>
        </div>

        <div
          class="flex flex-col border mt-4 border-black rounded-xl py-4 px-4"
        >
          <h1 class="text-3xl font-bold">
            Propietarios y Colaboradores Registrados entre Fechas
          </h1>
          <br />
          <p>
            Descargue el Reporte de Colaboradores y Propietarios Registrados
            entre la fecha inicial y final
          </p>
          <div class="mt-1 flex items-end">
            <div class="flex">
              <div class="">
                <p>Fecha Incial:</p>
                <div
                  class="w-44 h-8 border-2 rounded-md border-black text-center"
                >
                  <input
                    class="outline-none"
                    type="datetime-local"
                    id="fecha_inicial"
                  />
                </div>
              </div>
              <div class="ml-32">
                <p>Fecha Final:</p>
                <div
                  class="w-44 h-8 border-2 rounded-md border-black text-center"
                >
                  <input
                    class="outline-none"
                    type="datetime-local"
                    id="fecha_final"
                  />
                </div>
              </div>
            </div>
            <div
              @click="usersRegistersDates()"
              class="h-10 cursor-pointer ml-40 bg-yellow w-28 border-black border flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              Descargar
            </div>
          </div>
        </div>

        <div
          class="flex flex-col border border-black rounded-xl py-4 px-4 mt-4"
        >
          <h1 class="text-3xl font-bold">
            Propietarios y Colaboradores Eliminados
          </h1>
          <br />
          <p>
            Seleccione la fecha máxima y descargue el Reporte de Colaboradores y
            Propietarios Eliminados
          </p>
          <div class="mt-1 flex">
            <div class="w-44 border-2 h-8 rounded-md border-black text-center">
              <input
                class="outline-none"
                type="datetime-local"
                id="fechaUsuarioInactivo"
              />
            </div>
            <div
              @click="usersInactive()"
              class="h-10 cursor-pointer ml-40 bg-yellow w-28 border-black border flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              Descargar
            </div>
          </div>
        </div>

        <!-- <div
          class="flex flex-col mt-4 border border-black rounded-xl py-4 px-4"
        >
          <h1 class="text-3xl font-bold">Retos por Periodo</h1>
          <br />
          <p>Descargue el reporte por periodo</p>
          <div class="w-full flex flex-wrap items-center justify-around gap-7">
            <div
              @click="challengesP02()"
              class="h-10 cursor-pointer bg-yellow w-28 border-black border flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P02
            </div>
            <div
              @click="challengesP03()"
              class="h-10 cursor-pointer bg-yellow w-28 border-black border flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P03
            </div>
            <div
              @click="challengesP04()"
              class="h-10 cursor-pointer bg-yellow w-28 border-black border flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P04
            </div>
            <div
              @click="challengesP05()"
              class="h-10 cursor-pointer bg-yellow w-28 border-black border flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P05
            </div>
            <div
              @click="challengesP06()"
              class="h-10 cursor-pointer bg-yellow w-28 border-black border flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P06
            </div>
            <div
              @click="challengesP07()"
              class="h-10 cursor-pointer bg-yellow w-28 border-black border flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P07
            </div>
            <div
              @click="challengesP08()"
              class="h-10 cursor-pointer bg-yellow w-28 border-black border flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P08
            </div>
            <div
              @click="challengesP09()"
              class="h-10 cursor-pointer bg-yellow w-28 border-black border flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P09
            </div>
            <div
              @click="challengesP10()"
              class="h-10 cursor-pointer bg-yellow w-28 border-black border flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P10
            </div>
            <div
              @click="challengesP11()"
              class="h-10 cursor-pointer bg-yellow w-28 border-black border flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P11
            </div>
            <div
              @click="challengesP12()"
              class="h-10 cursor-pointer bg-yellow w-28 border-black border flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P12
            </div>
            <div
              @click="challengesP13()"
              class="h-10 cursor-pointer bg-yellow w-28 border-black border flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P13
            </div>
          </div>
        </div> -->

        <div
          class="flex flex-col mt-4 border border-black rounded-xl py-4 px-4"
        >
          <h1 class="text-3xl font-bold">Reporte de Trivias</h1>
          <br />
          <p>Descargue el reporte de trivias</p>
          <div class="grid grid-cols-3">
            <div
              @click="getTrivias()"
              class="h-10 cursor-pointer ml-40 bg-yellow w-28 border-black border flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              Descargar
            </div>
          </div>
        </div>

        <div
          class="flex flex-col border mt-4 border-black rounded-xl py-4 px-4"
        >
          <h1 class="text-3xl font-bold">Redenciones entre Fechas</h1>
          <br />
          <p>
            Descargue el Reporte de rendenciones entre la fecha inicial y final
          </p>
          <div class="mt-1 flex items-end">
            <div class="flex">
              <div class="">
                <p>Fecha Incial:</p>
                <div
                  class="w-44 h-8 border-2 rounded-md border-black text-center"
                >
                  <input
                    class="outline-none"
                    type="datetime-local"
                    v-model="initialDate"
                  />
                </div>
              </div>
              <div class="ml-32">
                <p>Fecha Final:</p>
                <div
                  class="w-44 h-8 border-2 rounded-md border-black text-center"
                >
                  <input
                    class="outline-none"
                    type="datetime-local"
                    v-model="finalDate"
                  />
                </div>
              </div>
            </div>
            <div
              @click="redemptionsDate()"
              class="h-10 cursor-pointer ml-40 bg-yellow w-28 border-black border flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              Descargar
            </div>
          </div>
        </div>

        <div
          class="flex flex-col mt-4 border border-black rounded-xl py-4 px-4"
        >
          <h1 class="text-3xl font-bold">Reporte de Puntos por Cumplimiento</h1>
          <br />
          <p>Descargue el reporte de puntos por cumplimiento</p>
          <div class="grid grid-cols-3">
            <div
              @click="getPointsCompliancePeriod()"
              class="h-10 cursor-pointer ml-40 bg-yellow w-36 border-black border flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              Colaboradores
            </div>

            <div
              @click="getPointsCompanies()"
              class="h-10 cursor-pointer ml-40 bg-yellow w-28 border-black border flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              Empresas
            </div>
          </div>
        </div>

        <div
          class="flex flex-col mt-4 border border-black rounded-xl py-4 px-4"
        >
          <h1 class="text-3xl font-bold">Reporte campaña banner Temptations</h1>
          <br />
          <p>
            Descargue el reporte de puntos asignados por foto campaña
            Temptations
          </p>
          <div class="grid grid-cols-3">
            <div
              @click="getPointsTemptationsCampaign()"
              class="h-10 cursor-pointer ml-40 bg-yellow w-28 border-black border flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              Descargar
            </div>
          </div>
        </div>

        <div
          class="flex flex-col mt-4 border border-black rounded-xl py-4 px-4"
        >
          <h1 class="text-3xl font-bold">Reporte de Puntos</h1>
          <br />
          <p>
            Descargue el reporte de puntos asignados y redimidos para cada
            usuario
          </p>
          <div class="grid grid-cols-3">
            <div
              @click="getPointsReport()"
              class="h-10 cursor-pointer ml-40 bg-yellow w-28 border-black border flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              Descargar
            </div>
          </div>
        </div>

        <div
          class="flex flex-col mt-4 border border-black rounded-xl py-4 px-4"
        >
          <h1 class="text-3xl font-bold">Reporte de Puntos 2024</h1>
          <br />
          <p>
            Descargue el reporte de puntos asignados y redimidos para cada
            empresa
          </p>
          <div class="grid grid-cols-3">
            <div
              @click="getPoints2024Report()"
              class="h-10 cursor-pointer ml-40 bg-yellow w-28 border-black border flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              Descargar
            </div>
          </div>
        </div>

        <div
          class="flex flex-col mt-4 border border-black rounded-xl py-4 px-4"
        >
          <h1 class="text-3xl font-bold">Reporte de Puntos manuales P10</h1>
          <br />
          <p>Descargue el reporte usuarios con 30 puntos asignados en p10</p>
          <div class="grid grid-cols-3">
            <div
              @click="getPointsP10Report()"
              class="h-10 cursor-pointer ml-40 bg-yellow w-28 border-black border flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              Descargar
            </div>
          </div>
        </div>

        <div
          class="flex flex-col mt-4 border border-black rounded-xl py-4 px-4"
        >
          <h1 class="text-3xl font-bold">
            Reporte de no participación en retos
          </h1>
          <br />
          <p>
            Descargue el reporte de colaboradores que no han participado en el
            reto correspondiente
          </p>
          <div class="w-full flex items-center justify-start flex-wrap gap-5">
            <div
              @click="getNoPartipationReport('p02')"
              class="cursor-pointer bg-yellow px-8 py-2 flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P02 - P03
            </div>
            <div
              @click="getNoPartipationReport('p04')"
              class="cursor-pointer bg-yellow px-8 py-2 flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P04
            </div>
            <div
              @click="getNoPartipationReport('p05')"
              class="cursor-pointer bg-yellow px-8 py-2 flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P05
            </div>
            <div
              @click="getNoPartipationReport('p06')"
              class="cursor-pointer bg-yellow px-8 py-2 flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P06
            </div>
            <div
              @click="getNoPartipationReport('p07')"
              class="cursor-pointer bg-yellow px-8 py-2 flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P07
            </div>
            <div
              @click="getNoPartipationReport('p08')"
              class="cursor-pointer bg-yellow px-8 py-2 flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P08
            </div>
            <div
              @click="getNoPartipationReport('p09')"
              class="cursor-pointer bg-yellow px-8 py-2 flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P09
            </div>
            <div
              @click="getNoPartipationReport('p10')"
              class="cursor-pointer bg-yellow px-8 py-2 flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P10
            </div>
          </div>
        </div>

        <div
          class="flex flex-col mt-4 border border-black rounded-xl py-4 px-4"
        >
          <h1 class="text-3xl font-bold">
            Reporte de no participación en Academia Mars
          </h1>
          <br />
          <p>
            Descargue el reporte de colaboradores que no han participado en el
            reto correspondiente
          </p>
          <div class="w-full flex items-center justify-start flex-wrap gap-5">
            <div
              @click="getNoPartipationAcademy('p02')"
              class="cursor-pointer bg-yellow px-8 py-2 flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P02 - P03
            </div>
            <div
              @click="getNoPartipationAcademy('p04')"
              class="cursor-pointer bg-yellow px-8 py-2 flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P04
            </div>
            <div
              @click="getNoPartipationAcademy('p05')"
              class="cursor-pointer bg-yellow px-8 py-2 flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P05
            </div>
            <div
              @click="getNoPartipationAcademy('p06')"
              class="cursor-pointer bg-yellow px-8 py-2 flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P06
            </div>
            <div
              @click="getNoPartipationAcademy('p07')"
              class="cursor-pointer bg-yellow px-8 py-2 flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P07
            </div>
            <div
              @click="getNoPartipationAcademy('p08')"
              class="cursor-pointer bg-yellow px-8 py-2 flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P08
            </div>
            <div
              @click="getNoPartipationAcademy('p09')"
              class="cursor-pointer bg-yellow px-8 py-2 flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P09
            </div>
            <div
              @click="getNoPartipationAcademy('p10')"
              class="cursor-pointer bg-yellow px-8 py-2 flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P10
            </div>
          </div>
        </div>

        <!-- <div class="flex flex-col mt-4 border border-black rounded-xl py-4  px-4">
          <h1 class="text-3xl font-bold">Reporte de redenciones estrella navideña</h1>
          <br/><p>Descargue el reporte de redenciones estrella navideña</p>
          <div class="w-full flex items-center justify-around flex-wrap gap-5">
              <div  @click="getCsRedemtionsReport" class="cursor-pointer bg-yellow px-8 py-2 border-black border flex text-white font-bold text-lg items-center justify-center rounded-md">
                  Descargar
              </div>
          </div>
        </div> -->

        <div
          class="flex flex-col mt-4 border border-black rounded-xl py-4 px-5"
        >
          <h1 class="text-3xl font-bold">Retos por Periodo</h1>
          <span>
            <p>Descargue el reporte de retos por periodo</p>
          </span>
          <div class="w-full flex flex-wrap items-center justify-start gap-3">
            <div
              @click="challengesP02_2024()"
              class="h-10 cursor-pointer bg-yellow hover:bg-yellow-500 w-28 border-black flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P02 - P03
            </div>
            <div
              @click="challengesP04_2024()"
              class="h-10 cursor-pointer bg-yellow hover:bg-yellow-500 w-28 border-black flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P04
            </div>
            <div
              @click="challengesP05_2024()"
              class="h-10 cursor-pointer bg-yellow hover:bg-yellow-500 w-28 border-black flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P05
            </div>
            <div
              @click="challengesP06_2024()"
              class="h-10 cursor-pointer bg-yellow hover:bg-yellow-500 w-28 border-black flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P06
            </div>
            <div
              @click="challengesP07_2024()"
              class="h-10 cursor-pointer bg-yellow hover:bg-yellow-500 w-28 border-black flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P07
            </div>
            <div
              @click="challengesP08_2024()"
              class="h-10 cursor-pointer bg-yellow hover:bg-yellow-500 w-28 border-black flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P08
            </div>
            <div
              @click="challengesP09_2024()"
              class="h-10 cursor-pointer bg-yellow hover:bg-yellow-500 w-28 border-black flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P09
            </div>
            <div
              @click="challengesP10_2024()"
              class="h-10 cursor-pointer bg-yellow hover:bg-yellow-500 w-28 border-black flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P10
            </div>
          </div>
        </div>
        <div
          class="flex flex-col mt-4 border border-black rounded-xl py-4 px-5"
        >
          <h1 class="text-3xl font-bold">Reporte de cursos de Academia Mars</h1>
          <span>
            <p>Descargue el reporte de los cursos de Academia Mars</p>
          </span>
          <div class="w-full flex flex-wrap items-center justify-start gap-3">
            <div
              @click="getCourseP02_2024()"
              class="h-10 cursor-pointer bg-yellow hover:bg-yellow-500 w-28 border-black flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P02 - P03
            </div>
            <div
              @click="getCourseP04_2024()"
              class="h-10 cursor-pointer bg-yellow hover:bg-yellow-500 w-28 border-black flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P04
            </div>
            <div
              @click="getCourseP05_2024()"
              class="h-10 cursor-pointer bg-yellow hover:bg-yellow-500 w-28 border-black flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P05
            </div>
            <div
              @click="getCourseP06_2024()"
              class="h-10 cursor-pointer bg-yellow hover:bg-yellow-500 w-28 border-black flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P06
            </div>
            <div
              @click="getCourseP07_2024()"
              class="h-10 cursor-pointer bg-yellow hover:bg-yellow-500 w-28 border-black flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P07
            </div>
            <div
              @click="getCourseP08_2024()"
              class="h-10 cursor-pointer bg-yellow hover:bg-yellow-500 w-28 border-black flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P08
            </div>
            <div
              @click="getCourseP09_2024()"
              class="h-10 cursor-pointer bg-yellow hover:bg-yellow-500 w-28 border-black flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P09
            </div>
            <div
              @click="getCourseP10_2024()"
              class="h-10 cursor-pointer bg-yellow hover:bg-yellow-500 w-28 border-black flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              P10
            </div>
          </div>
        </div>

        <div
          class="flex flex-col mt-4 border border-black rounded-xl py-4 px-4"
        >
          <h1 class="text-3xl font-bold">
            Reporte redenciones de retos (2023)
          </h1>
          <br />
          <p>Descargue el reporte de redenciones de retos</p>
          <div class="grid grid-cols-3">
            <div
              @click="getRedemptionsByPoints()"
              class="h-10 cursor-pointer bg-yellow hover:bg-yellow-500 w-28 border-black flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              Descargar
            </div>
          </div>
        </div>

        <div
          class="flex flex-col mt-4 border border-black rounded-xl py-4 px-4"
        >
          <h1 class="text-3xl font-bold">Reporte de cuotas para empresas</h1>
          <br />
          <p>Descargue el reporte de cuota para empresas</p>
          <div class="grid grid-cols-3">
            <div
              @click="getCompanyInstallments()"
              class="h-10 cursor-pointer bg-yellow hover:bg-yellow-500 w-28 border-black flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              Descargar
            </div>
          </div>
        </div>

        <div
          class="flex flex-col mt-4 border border-black rounded-xl py-4 px-4"
        >
          <h1 class="text-3xl font-bold">Reporte cupos de empresas</h1>
          <br />
          <p>Cupos asignados y registrados de empresas</p>
          <div class="grid grid-cols-3">
            <div
              @click="getCompanyWorkers()"
              class="h-10 cursor-pointer bg-yellow hover:bg-yellow-500 w-28 border-black flex text-white font-bold text-lg items-center justify-center rounded-md"
            >
              Descargar
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loader :isLoading="loadingData" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Loader from "@/components/Loader";
export default {
  components: { Loader },
  data() {
    return {
      initialDate: "",
      finalDate: "",
      loadingData: false,
    };
  },
  methods: {
    ...mapActions([
      "reports/getUsers",
      "reports/getUsersInactive",
      "reports/getChallengeP02_2024",
      "reports/getChallengeP04_2024",
      "reports/getChallengeP05_2024",
      "reports/getChallengeP06_2024",
      "reports/getChallengeP07_2024",
      "reports/getChallengeP08_2024",
      "reports/getChallengeP09_2024",
      "reports/getChallengeP10_2024",
      "reports/getUsersDates",
      "reports/getTriviasGeneral",
      "reports/getRedemptionsByDate",
      "reports/getPointsCompliance",
      "reports/getPointsComplianceCompanies",
      "reports/getPointsTemptationsCampaign",
      "reports/getPointsReport",
      "reports/getPoints2024Report",
      "reports/getPointsP10Report",
      "reports/getNoPartipationReport",
      "reports/getNoPartipationAcademy",
      "reports/getCsRedemtionsReport",
      "reports/getCourseP02_2024",
      "reports/getCourseP04_2024",
      "reports/getCourseP05_2024",
      "reports/getCourseP06_2024",
      "reports/getCourseP07_2024",
      "reports/getCourseP08_2024",
      "reports/getCourseP09_2024",
      "reports/getCourseP10_2024",
      "reports/getRedemptionsByPoints",
      "reports/getCompanyInstallments",
    ]),
    async users() {
      const fechaInput = document.getElementById("fechaUsuario").value;
      if (fechaInput === "") {
        window.alert(
          "PROPIETARIOS Y COLABORADORES ACTIVOS: \n* Ingrese una Fecha en el Formulario"
        );
      } else {
        this.loadingData = true;
        var myDate = new Date(fechaInput);
        var myEpoch = myDate.getTime();
        await this["reports/getUsers"]({ myEpoch });
        this.loadingData = false;
      }
    },
    async usersInactive() {
      const fechaInput = document.getElementById("fechaUsuarioInactivo").value;
      if (fechaInput === "") {
        window.alert(
          "PROPIETARIOS Y COLABORADORES INACTIVOS: \n* Ingrese una Fecha en el Formulario"
        );
      } else {
        this.loadingData = true;
        var myDate = new Date(fechaInput);
        var myEpoch = myDate.getTime();
        await this["reports/getUsersInactive"]({ myEpoch });
        this.loadingData = false;
      }
    },
    async challengesP02_2024() {
      this.loadingData = true;
      await this["reports/getChallengeP02_2024"]();
      this.loadingData = false;
    },
    async challengesP04_2024() {
      this.loadingData = true;
      await this["reports/getChallengeP04_2024"]();
      this.loadingData = false;
    },
    async challengesP05_2024() {
      this.loadingData = true;
      await this["reports/getChallengeP05_2024"]();
      this.loadingData = false;
    },
    async challengesP06_2024() {
      this.loadingData = true;
      await this["reports/getChallengeP06_2024"]();
      this.loadingData = false;
    },
    async challengesP07_2024() {
      this.loadingData = true;
      await this["reports/getChallengeP07_2024"]();
      this.loadingData = false;
    },
    async challengesP08_2024() {
      this.loadingData = true;
      await this["reports/getChallengeP08_2024"]();
      this.loadingData = false;
    },
    async challengesP09_2024() {
      this.loadingData = true;
      await this["reports/getChallengeP09_2024"]();
      this.loadingData = false;
    },
    async challengesP10_2024() {
      this.loadingData = true;
      await this["reports/getChallengeP10_2024"]();
      this.loadingData = false;
    },
    async getTrivias() {
      this.loadingData = true;
      await this["reports/getTriviasGeneral"]();
      this.loadingData = false;
    },
    async getPointsCompliancePeriod() {
      this.loadingData = true;
      await this["reports/getPointsCompliance"]();
      this.loadingData = false;
    },
    async usersRegistersDates() {
      const fechaInicial = document.getElementById("fecha_inicial").value;
      const fechaFinal = document.getElementById("fecha_final").value;
      if (fecha_inicial === "" || fecha_final === "") {
        window.alert(
          "PROPIETARIOS Y COLABORADORES ENTRE FECHAS: \n* Ingrese las Fechaa en el Formulario"
        );
      } else {
        this.loadingData = true;
        var myDateInit = new Date(fechaInicial);
        var myDateFinal = new Date(fechaFinal);
        var myEpochInit = myDateInit.getTime();
        var myEpochFinal = myDateFinal.getTime();
        await this["reports/getUsersDates"]({ myEpochInit, myEpochFinal });
        this.loadingData = false;
      }
    },
    async redemptionsDate() {
      console.log(this.initialDate);

      if (this.initialDate === "" || this.finalDate === "") {
        window.alert("REDENCIONES \n* Ingrese las Fechas en el Formulario");
      } else {
        this.loadingData = true;
        var myDateInit = new Date(this.initialDate);
        var myDateFinal = new Date(this.finalDate);
        var myEpochInit = myDateInit.getTime();
        var myEpochFinal = myDateFinal.getTime();
        await this["reports/getRedemptionsByDate"]({
          myEpochInit,
          myEpochFinal,
        });
        this.loadingData = false;
      }
    },
    async getPointsCompanies() {
      this.loadingData = true;
      await this["reports/getPointsComplianceCompanies"]();
      this.loadingData = false;
    },
    async getPointsTemptationsCampaign() {
      this.loadingData = true;
      await this["reports/getPointsTemptationsCampaign"]();
      this.loadingData = false;
    },
    async getPointsReport() {
      this.loadingData = true;
      await this["reports/getPointsReport"]();
      this.loadingData = false;
    },
    async getPoints2024Report() {
      this.loadingData = true;
      await this["reports/getPoints2024Report"]();
      this.loadingData = false;
    },
    async getPointsP10Report() {
      this.loadingData = true;
      await this["reports/getPointsP10Report"]();
      this.loadingData = false;
    },
    async getNoPartipationReport(challengeId) {
      this.loadingData = true;
      await this["reports/getNoPartipationReport"](challengeId);
      this.loadingData = false;
    },
    async getNoPartipationAcademy(challengeId) {
      this.loadingData = true;
      await this["reports/getNoPartipationAcademy"](challengeId);
      this.loadingData = false;
    },
    async getCsRedemtionsReport() {
      this.loadingData = true;
      await this["reports/getCsRedemtionsReport"]();
      this.loadingData = false;
    },
    async getCourseP02_2024() {
      this.loadingData = true;
      await this["reports/getCourseP02_2024"]();
      this.loadingData = false;
    },
    async getCourseP04_2024() {
      this.loadingData = true;
      await this["reports/getCourseP04_2024"]();
      this.loadingData = false;
    },
    async getCourseP05_2024() {
      this.loadingData = true;
      await this["reports/getCourseP05_2024"]();
      this.loadingData = false;
    },
    async getCourseP06_2024() {
      this.loadingData = true;
      await this["reports/getCourseP06_2024"]();
      this.loadingData = false;
    },
    async getCourseP07_2024() {
      this.loadingData = true;
      await this["reports/getCourseP07_2024"]();
      this.loadingData = false;
    },
    async getCourseP08_2024() {
      this.loadingData = true;
      await this["reports/getCourseP08_2024"]();
      this.loadingData = false;
    },
    async getCourseP09_2024() {
      this.loadingData = true;
      await this["reports/getCourseP09_2024"]();
      this.loadingData = false;
    },
    async getCourseP10_2024() {
      this.loadingData = true;
      await this["reports/getCourseP10_2024"]();
      this.loadingData = false;
    },
    async getRedemptionsByPoints() {
      this.loadingData = true;
      await this["reports/getRedemptionsByPoints"]();
      this.loadingData = false;
    },
    async getCompanyInstallments() {
      this.loadingData = true;
      await this["reports/getCompanyInstallments"]();
      this.loadingData = false;
    },
  },
};
</script>
